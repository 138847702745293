import superagent from 'superagent'

type TLogin = {
  location: string
  success: string
}

const postLoginRequest = (login: string, password: string): Promise<TLogin> => {
  const csrfToken: HTMLMetaElement = document.querySelector(
    "meta[name='csrf-token']"
  )

  return superagent
    .post('/user_sessions')
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .set('X-CSRF-Token', csrfToken ? csrfToken.content : null)
    .send({ user_session: { login, password } })
    .then((response) => {
      return response.body
    })
    .catch((err) => {
      return Promise.reject(err.response.text)
    })
}

export default {
  postLoginRequest,
}
