import React from 'react'
import ReactToolTip from 'react-tooltip'

const BrowserIcons = () => {
  return (
    <div className='o-flexGrid u-alignItemsFlexEnd u-flexDirectionColumn'>
      <div>
        <h4>Supported Browsers</h4>
        <div className='o-flexGrid u-justifyContentSpaceBetween'>
          <div
            className='h-browserIcon h-browserIcon--chrome'
            data-tip='Google Chrome'
          />
          <div
            className='h-browserIcon h-browserIcon--firefox'
            data-tip='Mozilla Firefox'
          />
          <div
            className='h-browserIcon h-browserIcon--edge'
            data-tip='Microsoft Edge'
          />
          <div
            className='h-browserIcon h-browserIcon--safari'
            data-tip='Apple Safari'
          />
        </div>
        <ReactToolTip place={'bottom'} className={'h-tooltip'} />
      </div>
    </div>
  )
}

export default BrowserIcons
