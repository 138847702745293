import React from 'react'
import { render } from 'react-dom'
import Login from 'Login/entry'

const mount = document.getElementById('login_app')

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('alert_data')
  const data = JSON.parse(node.getAttribute('data'))

  render(<Login alertData={data} />, mount)
})
