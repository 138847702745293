import React, { useRef, useEffect } from 'react'
import RaccButton from 'Shared/components/button'
import PropTypes from 'prop-types'

const UsernameInputForm = (props) => {
  const { username, setUsername, display } = props
  const usernameInput = useRef()
  let className = 'o-flexGrid u-alignItemsCenter'
  if (!display) {
    className += ' ui-helper-hidden'
  }

  useEffect(() => {
    if (display) {
      usernameInput.current.focus()
    }
  }, [display])

  return (
    <div
      id='username_container'
      className={className}
      data-testid='usernameField'
    >
      <input
        id='login_field'
        className='h-input u-width300 u-marginRight'
        placeholder='Username'
        value={username}
        ref={usernameInput}
        onChange={(e) => setUsername(e.target.value.trim())}
        autoComplete='username'
        data-testid='userInput'
      />
      <RaccButton value='Next' id='login_next' type='submit' />
    </div>
  )
}

UsernameInputForm.propTypes = {
  username: PropTypes.string.isRequired,
  setUsername: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
}

export default UsernameInputForm
